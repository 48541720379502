<template>
  <div class="pre-storage-layout withTopBar">
    <van-nav-bar title="鲜奶预存" left-text="返回" left-arrow border safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
    <div class="scroll-box" ref="scroll">
      <div class="loading" v-show="loading">
        <van-loading type="spinner" color="#1989fa" />
      </div>
      <van-empty v-show="!loading && preData.length==0" description="暂无数据" />
      <div class="holder">
        <div class="item" v-for="(item,index) in preData" :key="'pre'+index">
          <div class="flex_box align_center space_between mb10">
            <div class="font15">{{item.d_title}}</div>
            <!--<div class="btn" v-if="item.activate=='0' && item.pay_state =='1'">
              激活配送
            </div>-->
          </div>
          <div class="flex_box space_between mb5">
            <div class="product-img">
              <img class="" :src="item.pic" />
            </div>
            <div class="flex_one pdl10">
              <div class="font16 mb5">{{item.title}}</div>
              <div class="flex_box align_center space_between mb5 color_gray_two font15">
                <div class="flex_box align_center">
                  <span class="">{{item.total_num}}份</span>
                  <span class="pdl10" >共{{item.num}}瓶</span>
                </div>
                <span class="text-throung">原价{{item.org_total_money}}元</span>
              </div>
              <div class="pay">
                实付{{item.pay_money =='' && item.deduct_overage =='' ? 0 : item.pay_money !='' ? item.pay_money : item.deduct_overage}}元
              </div>
            </div>
          </div>
          <div class="font14 mb5">
            <span class="">支付方式：</span>
            <span class="color_gray_two">{{item.pay_model_str}}</span>
          </div>
          <div class="font14 flex_box align_center space_between">
            <div class="">
              <span class="" v-if="item.pay_time!=''">付款时间：</span>
              <span class="color_gray_two">{{item.pay_time}}</span>
            </div>
            <div class="quit-btn" v-if="item.activate=='0' && item.pay_state=='1'" @click="quitMoney(item)">
              退单
            </div>
            <div class="quit-btn" v-if="item.pay_state=='0'" @click="pay(item)">
              付款
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showCode" closeable :close-on-click-overlay="false" @closed="clearTimer">
          <div class="qrcode-box">
            <div class="text_center mb10 font16">请扫码支付</div>
            <div id="qrcode" ref="qrcode"></div>
            <div class="flex_box align_center mt10">
              <span class="mr10 font15">支付剩余时间：</span>
              <div class="">
                <van-count-down :time="time" @finish="finish">
                  <template #default="timeData">
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                  </template>
                </van-count-down>
              </div>
            </div>
          </div>
        </van-popup>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  name:"pre-storage",
  data(){
    return{
      loading:false,
      isMore:true,
      page:1,
      rowCount:10,
      preData:[],
      showCode:false,
      payUrl:"",//支付链接weixin://wxpay/bizpayurl?pr=vZjjA5kzz
      time: 5*60*1000,
      timeData:{
          minutes:5,
          seconds:0
      },
      timer:null
    }
  },
  created(){
    this.index()
  },
  mounted(){
    this.$refs.scroll.addEventListener('scroll',this.handleScroll,true);
  },
  methods:{
    index(){
      const _this = this
        _this.loading = true
        _this.$api.common.getDisOrderList({
            page:_this.page,
            rowCount:_this.rowCount
        }).then(res=>{
            _this.loading = false
            if(res.code =='0' && res.data){
                let result = _this.preData.concat(res.data.rows)
                _this.preData = result
                _this.page++
                if(res.data.rows.length < _this.rowCount){
                    _this.isMore = false
                }
            }
        }).catch(() => {
            _this.loading = false;
        })
    },
    pay(order){//继续支付
      const _this = this
      _this.$api.common.continuePayDisOrder({
          dis_order_id:order.dis_order_id
      }).then(res=>{
          if(res.code =="0" && res.data){
              _this.payUrl = res.data.code_url
              _this.CheckPaySate(res.data.order_id)
              _this.showCode = true
              _this.$nextTick(() => {
                  _this.qrcode()
              })
          }else{
            _this.$toast.fail(res.msg)
          }
      })
    },
    quitMoney(order){
      const _this = this
      _this.$dialog.confirm({
        title: '标题',
        message: '确定退款？',
      }).then(() => {
          _this.$toast.loading({
            message: '保存中...',
            forbidClick: true,
          })
          _this.$api.common.refundDisOrder({
              dis_order_id:order.dis_order_id
          }).then(res=>{
              _this.$toast.clear()
              if(res.code =="0" && res.data){
                _this.$toast.success('退单成功')
                _this.reload()
              }else{
                _this.$toast.fail('退单失败')
              }
          }).catch(()=>{
            _this.$toast.clear()
            _this.$toast.fail('退单失败')
          })
      })
      return false

      
    },
    handleScroll(){
      const _this = this

      const scrollTop = this.$refs.scroll.scrollTop
      // 获取可视区的高度
      const windowHeight = this.$refs.scroll.clientHeight
      // 获取滚动条的总高度
      const scrollHeight = this.$refs.scroll.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight ) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        if(_this.isMore && !_this.loading){
          _this.index()
        }
      }
    },
    finish(){
        const _this = this
        _this.$toast.fail('支付超时')
        _this.clearTimer()
        _this.showCode = false
    },
    qrcode() {
        const _this = this
        new QRCode("qrcode", {
            width: 300, // 二维码宽度，单位像素
            height: 300, // 二维码高度，单位像素
            text: _this.payUrl// 生成二维码的链接
        })
    },
    CheckPaySate(orderId){
      const _this = this
      this.timer=setInterval(()=>{
          _this.$api.common.getDisOrderPayState({
              dis_order_id:orderId
          }).then(res=>{
              if(res.code =="0" && res.data && res.data.pay_state == 1){
                  _this.clearTimer()
                  _this.showCode = false
                  _this.$toast.success('支付成功')
                  _this.reload()
              }
          })
      },5000)
  },
  reload(){
    this.page = 1
    this.isMore = true
    this.preData = []
    this.index()
  },
  clearTimer(){
      this.timer ? clearInterval(this.timer) : '' //关闭
  },
    onClickLeft(){
      this.$router.push({
        name: "home"
      })
    }
  }
}
</script>

<style lang="scss">
@import "./pre-storage.scss"
</style>